import React from "react";
import "./uni.css";
import { useNavigate } from "react-router-dom";

export default function Russia() {
  const Navigate = useNavigate()
  return (
    <div className="vbgy">
      <div className="dfhtu">
        <h2>MBBS in Moldova</h2>
      </div>
      <div className="wityu">
        <img src="https://i.ytimg.com/vi/CUuWs67L_6Y/maxresdefault.jpg"></img>

        <p>
          Moldova oficially the Republic of Moldova (Romanian :Republica
          Moldova),is a land-locked country in Eastern Europe . It is bordered
          by Romania to the west and Ukraine to the north , east, and south .
          The Unrecognised state of Transnistria lies across the Dnies-ter river
          on the country's eastern border with Ukraine. Moldova's capital and
          largest city is Chisinau.
          <br />
          <br />
          <button onClick={()=>{Navigate('/contact')}}>Apply Now</button>
        </p>
      </div>

      {/* 00000000000000000000000000000000000000000000000000000 */}

      <div className="nffd">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHsjZLls1-LD5NKbdipqfJhUskNNAPXFwoLg&usqp=CAU"></img>
        <div className="nhjip">
          <h3
            style={{
              backgroundColor: "#ff9818",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            A BRIEF LOOK AT MBBS In MOLDOVA
          </h3>
          <br />
          <br />
          <table className="ewrfd">
            <tr>
              <td>Capital</td>
              <td>Chisinau</td>
            </tr>
            <tr>
              <td>Recognition</td>
              <td>NMC (MCI), WHO, ECFMG, FAIMER, WDOMS</td>
            </tr>
            <tr>
              <td>Course Duration</td>
              <td>6 Years(5 + 1 Year's internship)</td>
            </tr>
            <tr>
              <td>Average cost of stay (Fee included)</td>
              <td>20-25 lakhs(Approx)</td>
            </tr>
            <tr>
              <td>Intake</td>
              <td>Summer & Winter</td>
            </tr>
            <tr>
              <td>Accomodation Facilities</td>
              <td>Hostel & Apartment</td>
            </tr>
            <tr>
              <td>Indian Mess</td>
              <td>Avilable</td>
            </tr>
          </table>
        </div>
      </div>

      {/* 0000000000000000000000000000000000000000000000000000000000000000000000    */}
      <div className="cxdsf">
        <div className="ugvuids">
          <h2>UNIVERSITY OF MOLDOVA</h2>
          <table className="table" data-aos="zoom-in-up" data-aos-duration="1300" data-aos-easing="linear">
                <tr>
                    <th className="th title2">UNIVERSITY NAME</th>
                    <th className="th title2">TUTIONFEE</th>
                   
                </tr>
                {/* <!-- 1 --> */}
                <tr>
                    <td className="td dflex dflexMob"><span>1.</span>Nicole Testemitanu state University of Medicine and Pharmacy</td>
                    <td className="td">5000 Euro</td>
                   
                </tr>
                
                    
            </table>
        </div>
      </div>
      <section className="ugvui">
        <ul>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Above Mentioned Tution Fee Changable According to Their Academic
            Session.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Admission Fee, Documentation & VISA Processing Excluded in Package.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Form 2nd to 6th year's Medical Insurance & VISA Extension will be
            Extra.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Flight Ticket Charge Excluded.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <span>
              <b>Note:</b>
            </span>
            <br />
            In certain case students have to pay either partial or full amount
            of tuition fee to the university soon after receive letter, as per
            the University instruction
          </li>
        </ul>
      </section>

      {/* 0000000000000000000000000000000000000000000000000000000000000000000000 */}

      {/* <div className="gsfas">
        <h3>BASHKIR STATE MEDICAL UNIVERSITY</h3>

        <div className="sdgdfghf">
          <img src="https://www.metaeducationindia.com/wp-content/uploads/2021/02/Bashkir-state-medical-university.jpg"></img>
          <p>
            Bashkir State Medical University was established in 1932. It is one
            of the leading medical universities in the Russian Federation. With
            more than 8000 students from 40 different countries, it is the most
            suitable choice for MBBS in Russia. Formerly introduced as
            Bashkortostan Institute in Ufa, Bashkir State Medical University is
            the top institution in the center of the medical and pharmaceutical
            sciences of the Republic of Bashkortostan.
            <br />
            <strong>pESTO. : 1932</strong>
            <br />
            <strong>University Type : Government</strong>
            <br />
            <strong>Medium : English</strong>
            <br />
            <strong>ESTO. : No. of Indian Students : 500+</strong>
            <br />
          </p>
        </div>
      </div> */}

      {/* 0000000000000000000000000000000000000000000000000 */}

      {/* <div className="packageForFirstYear">
        <table className="table fgjhfghfg" border="1">
          <h2 style={{ textAlign: "center", width: "100%" }}>
            PACKAGE FOR 1ST YEAR STUDENT
          </h2>
          <thead>
            <tr rules="rows">
              <td>TUITION FEE</td>
              <td>HOSTEL FEE</td>
              <td>
                IMMIGRATION CLERANCE,AIRPORT PICKUP, ARRIVAL MEAL, TRANSLATION
                AND ATTESTATION OF DOCUMENTS IN UNIVERSITY, MEDICAL INSURANCE &
                MEDICAL CHECKUP, LOCAL POLICE VERIFICATION, BIOMETRIC AND VISA
                EXTENTION, HOSTEL ALLOTMENT, BANK ACCOUNT OPENING, LOCAL SUPPORT
                ETC
              </td>
              <td>INCENTIVE</td>
            </tr>

            <tr>
              <td>2.94,120RUB</td>
              <td>13,00RUB</td>
              <td>2300$</td>
              <td>1000$</td>
            </tr>
          </thead>
        </table>
        <p className="para border">FOOD FEE PER MONTH - 120$</p>
        <p className="para border pb0">
          ADMISSION FEE, VISA PROCESSING & DOCUMENTATION ( 25-35000) | FLIGHT
          TICKET AS PER ACTUAL.
        </p>
      </div> */}

      {/* 000000000000000000000000000000000000000000000000000000000000000 */}

      {/* <div
        className="packageForSixYear"
        data-aos="zoom-out"
        data-aos-duration="1000"
        data-aos-easing="linear"
      >
        <h3 className="packagewhite">PACKAGE FOR 2ND TO 6TH YEAR STUDENT</h3>
        <table className="table">
          <tr>
            <th className="th title2">Package</th>
            <th className="th title2">2nd</th>
            <th className="th title2">3rd</th>
            <th className="th title2">4th</th>
            <th className="th title2">5th</th>
            <th className="th title2">6th</th>
          </tr>

          <tr>
            <td className="td ">Tuition Fee</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
          </tr>

          <tr>
            <td className="td">Hostel Fee</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
          </tr>

          <tr>
            <td className="td">Medical Insurance VISA Extention</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
          </tr>
        </table>
      </div>

      <div className="mainSec">
        <ul>
          <li className="para">
            <span>
              {" "}
              IMPORTANT NOTE <b>:</b>
            </span>
            <br />
            <br />
            <p
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              BSMU has a huge clinic where over 24000 patients visit annually
              which helps students to be in practical touch. <br />
            </p>
            <p
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              Bashkir State Medical University provides Simulation Based
              Training in the Simulation Centre.
              <br />
            </p>
            <p
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              The University provides Russian language training for bilingual
              coaching.
              <br />
            </p>
            <p
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              University has a diverse culture with 8000 foreign students coming
              from 40 different countries. <br />
            </p>
            <p
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              There are fully equipped laboratories with modern equipment.
            </p>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
