import React from "react";
import "./uni.css";
import { useNavigate } from "react-router-dom";

export default function Russia() {
  const Navigate = useNavigate()
  return (
    <div className="vbgy">
      <div className="dfhtu">
        <h2>MBBS in Germany</h2>
      </div>
      <div className="wityu">
        <img src="https://europecareers.in/wp-content/uploads/2023/03/MBBS-In-Germany.jpeg"></img>

        <p>
          Germany officially the Federal Republic of Germany is a country in
          central Europe it is the Second most Populous country in Europe after
          Russia , and the most Populous member state of the European Union .
          Germany is situated between the Baltic and North seas to the north and
          Alps to the south . its 16 constituent states are bordered by Denmark
          to the north Poland and the CZech Republic to the East , Austria and
          switzerland to the south and France Luxembourg ,Belgium and the
          Netherlands to the West . The Nation's capital and most populous city
          is Berlin and its main financial centre is Frankfurt the largest Urban
          area is the Ruhr.
          <br />
          <br />
          <button onClick={()=>{Navigate('/contact')}}>Apply Now</button>
        </p>
      </div>

      {/* 00000000000000000000000000000000000000000000000000000 */}

      <div className="nffd">
        <img src="https://www.themdhouse.com/blog/wp-content/uploads/2020/08/mbbs-in-germany-1-1024x682.jpg"></img>
        <div className="nhjip">
          <h3
            style={{
              backgroundColor: "#ff9818",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            A BRIEF LOOK AT MBBS In Germany
          </h3>
          <br />
          <br />
          <table className="ewrfd">
            <tr>
              <td>Capital</td>
              <td>Berlin</td>
            </tr>
            <tr>
              <td>Population</td>
              <td>8.32 crores(2021)</td>
            </tr>
            <tr>
              <td>Language</td>
              <td>German</td>
            </tr>
            <tr>
              <td>Medium of Instruction</td>
              <td>English</td>
            </tr>
            <tr>
              <td>Currency</td>
              <td>Euro(1 Euro=90.27 INR)</td>
            </tr>
            <tr>
              <td>International Airport</td>
              <td>Berlin</td>
            </tr>
            <tr>
              <td>Recognition</td>
              <td>NMC (MCI), WHO, ECFMG, FAIMER, WDOMS</td>
            </tr>
            <tr>
              <td>Course Duration</td>
              <td>6 Years(5 + 1 Year's internship)</td>
            </tr>
            <tr>
              <td>Average cost of stay (Fee included)</td>
              <td>25-50 lakhs(Approx)</td>
            </tr>
            <tr>
              <td>Intake</td>
              <td>Summer & Winter</td>
            </tr>
          </table>
        </div>
      </div>

      {/* 0000000000000000000000000000000000000000000000000000000000000000000000    */}
      <div className="cxdsf">
        <div className="ugvuids">
          <h2>UNIVERSITY OF GERMANY</h2>
          <table className="table" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                <tr>
                    <th className="th title2">UNIVERSITY NAME</th>
                </tr>
                {/* <!-- 1 --> */}
                <tr>
                    <td className="td dflex dflexMob"><span>1.</span>Ludwig Maxmilian-Universitat Munchen</td>
                </tr>
                {/* <!-- 2 --> */}
                <tr>
                    <td className="td dflex dflexMob"><span>2.</span>Humboldt-Universitat zu Berlin</td>
                </tr>
                {/* <!-- 3 --> */}
                    <tr>
                    <td className="td dflex dflexMob"><span>3.</span>Universitat Heidelberg </td>
                </tr>
                
                {/* <!-- 4 --> */}
                    <tr>
                    <td className="td dflex dflexMob"><span>4.</span>Freie Universitat Berlin</td>
                </tr>
                
                {/* <!-- 5 --> */}
                    
            </table>
        </div>
      </div>
      <section className="ugvui">
        <ul>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Above Mentioned Tution Fee Changable According to Their Academic
            Session.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Admission Fee, Documentation & VISA Processing Excluded in Package.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Form 2nd to 6th year's Medical Insurance & VISA Extension will be
            Extra.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            Flight Ticket Charge Excluded.
          </li>
          <li
            className="para"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <span>
              <b>Note:</b>
            </span>
            <br />
            In certain case students have to pay either partial or full amount
            of tuition fee to the university soon after receive letter, as per
            the University instruction
          </li>
        </ul>
      </section>

      {/* 0000000000000000000000000000000000000000000000000000000000000000000000 */}

      {/* <div className="gsfas">
        <h3>BASHKIR STATE MEDICAL UNIVERSITY</h3>

        <div className="sdgdfghf">
          <img src="https://www.metaeducationindia.com/wp-content/uploads/2021/02/Bashkir-state-medical-university.jpg"></img>
          <p>
            Bashkir State Medical University was established in 1932. It is one
            of the leading medical universities in the Russian Federation. With
            more than 8000 students from 40 different countries, it is the most
            suitable choice for MBBS in Russia. Formerly introduced as
            Bashkortostan Institute in Ufa, Bashkir State Medical University is
            the top institution in the center of the medical and pharmaceutical
            sciences of the Republic of Bashkortostan.
            <br />
            <strong>pESTO. : 1932</strong>
            <br />
            <strong>University Type : Government</strong>
            <br />
            <strong>Medium : English</strong>
            <br />
            <strong>ESTO. : No. of Indian Students : 500+</strong>
            <br />
          </p>
        </div>
      </div> */}

      {/* 0000000000000000000000000000000000000000000000000 */}

      {/* <div className="packageForFirstYear">
        <table className="table fgjhfghfg" border="1">
          <h2 style={{ textAlign: "center", width: "100%" }}>
            PACKAGE FOR 1ST YEAR STUDENT
          </h2>
          <thead>
            <tr rules="rows">
              <td>TUITION FEE</td>
              <td>HOSTEL FEE</td>
              <td>
                IMMIGRATION CLERANCE,AIRPORT PICKUP, ARRIVAL MEAL, TRANSLATION
                AND ATTESTATION OF DOCUMENTS IN UNIVERSITY, MEDICAL INSURANCE &
                MEDICAL CHECKUP, LOCAL POLICE VERIFICATION, BIOMETRIC AND VISA
                EXTENTION, HOSTEL ALLOTMENT, BANK ACCOUNT OPENING, LOCAL SUPPORT
                ETC
              </td>
              <td>INCENTIVE</td>
            </tr>

            <tr>
              <td>2.94,120RUB</td>
              <td>13,00RUB</td>
              <td>2300$</td>
              <td>1000$</td>
            </tr>
          </thead>
        </table>
        <p className="para border">FOOD FEE PER MONTH - 120$</p>
        <p className="para border pb0">
          ADMISSION FEE, VISA PROCESSING & DOCUMENTATION ( 25-35000) | FLIGHT
          TICKET AS PER ACTUAL.
        </p>
      </div> */}

      {/* 000000000000000000000000000000000000000000000000000000000000000 */}

      {/* <div
        className="packageForSixYear"
        data-aos="zoom-out"
        data-aos-duration="1000"
        data-aos-easing="linear"
      >
        <h3 className="packagewhite">PACKAGE FOR 2ND TO 6TH YEAR STUDENT</h3>
        <table className="table">
          <tr>
            <th className="th title2">Package</th>
            <th className="th title2">2nd</th>
            <th className="th title2">3rd</th>
            <th className="th title2">4th</th>
            <th className="th title2">5th</th>
            <th className="th title2">6th</th>
          </tr>

          <tr>
            <td className="td ">Tuition Fee</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
            <td className="td">2,94,120RUB</td>
          </tr>

          <tr>
            <td className="td">Hostel Fee</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
            <td className="td">13,000RUB</td>
          </tr>

          <tr>
            <td className="td">Medical Insurance VISA Extention</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
            <td className="td">200$</td>
          </tr>
        </table>
      </div>

      <div className="mainSec">
        <ul>
          <li className="para">
            <span>
              {" "}
              IMPORTANT NOTE <b>:</b>
            </span>
            <br />
            <br />
            <p
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              BSMU has a huge clinic where over 24000 patients visit annually
              which helps students to be in practical touch. <br />
            </p>
            <p
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              Bashkir State Medical University provides Simulation Based
              Training in the Simulation Centre.
              <br />
            </p>
            <p
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              The University provides Russian language training for bilingual
              coaching.
              <br />
            </p>
            <p
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              University has a diverse culture with 8000 foreign students coming
              from 40 different countries. <br />
            </p>
            <p
              data-aos="zoom-out"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              There are fully equipped laboratories with modern equipment.
            </p>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
